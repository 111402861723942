exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-b-2-b-terms-conditions-js": () => import("./../../../src/pages/legal/b2b-terms-conditions.js" /* webpackChunkName: "component---src-pages-legal-b-2-b-terms-conditions-js" */),
  "component---src-pages-legal-b-2-c-terms-conditions-js": () => import("./../../../src/pages/legal/b2c-terms-conditions.js" /* webpackChunkName: "component---src-pages-legal-b-2-c-terms-conditions-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */)
}

